<script>
import {useUserStore} from "@/stores/user";
import {eventBus} from "@/main";
import {useGlobalStore} from "@/stores/global";
import router from "@/router/routes";

export default {
  name: "NavigationDrawerContent",
  components: {},
  data() {
    return {
      items: [
        {title: "My Files", icon: "mdi-file", path: "/", requiredAdmin: false},
        {title: "Shared Files", icon: "mdi-share-variant", path: "/shared-with-me", requiredAdmin: false},
        {title: "All Files", icon: "mdi-file-multiple", path: "/all-files", requiredAdmin: true},
        {title: "Recent", icon: "mdi-history", path: "/recent", requiredAdmin: false},
        {title: "Users", icon: "mdi-account", path: "/users", requiredAdmin: true},
        {title: "Groups", icon: "mdi-account-multiple", path: "/groups", requiredAdmin: true},
      ],
      itemsBottom: [
        {title: "Livestreams", icon: 'mdi-quadcopter', path: "/livestream"},
        {title: "Map", icon: 'mdi-map', path: "/map"},
      ]
    };
  },
  computed: {
    isAdmin() {
      return useUserStore().isAdmin;
    },
    user() {
      return useUserStore().user;
    }
  },
  methods: {
    router() {
      return router;
    },
    useGlobalStore,
    addNewFile() {
      eventBus.$emit("toggle-new-file-dialog", true);
    },
  },

};
</script>

<template>
  <div>
    <v-list-item class="pt-2 pb-0" :class="useGlobalStore().navDrawerExpanded ? 'd-flex justify-center ' : ''">
      <v-btn color="primary " elevation="2" block dark @click="addNewFile()"
             :class="useGlobalStore().navDrawerExpanded ? 'px-2' : ''">
        <v-icon :class="!useGlobalStore().navDrawerExpanded ? 'pr-2' : ''">mdi-plus-circle-outline</v-icon>
        <span v-if="!useGlobalStore().navDrawerExpanded">Add Files</span>
      </v-btn>
    </v-list-item>

    <v-list dense rounded :class="useGlobalStore().navDrawerExpanded ? 'px-0' : ''">
      <div v-for="item in items" :key="item.title">
        <v-list-item :to="item.path" link v-if="!item.requiredAdmin || isAdmin">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
    <v-list-item v-if="!useGlobalStore().navDrawerExpanded" class="pb-0" two-line>
      <v-list-item-content>
        <v-list-item-title> Contingent:</v-list-item-title>
        <v-progress-linear :value="(100 * user.used_gb / user.contingent)"
                           color="blue-grey" height="25" striped rounded>
          <strong>{{ $helpers.formatGigaBytes(user.used_gb) }} /
            {{ $helpers.formatGigaBytes(user.contingent) }}</strong>
        </v-progress-linear>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <h4 v-if="!useGlobalStore().navDrawerExpanded" class="ml-6 mt-3">My Services</h4>
    <v-list dense rounded :class="useGlobalStore().navDrawerExpanded ? 'px-0' : ''">
      <v-list-item to="/livestream" link>
        <v-list-item-icon>
          <v-icon>mdi-quadcopter</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Livestreams</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-group :value="true" prepend-icon="mdi-earth" class="" active-class="black--text">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>EO Services</v-list-item-title>
          </v-list-item-content>
        </template>
          <v-list-item to="/map" link active-class="black--text">
            <v-list-item-icon>
              <v-icon>mdi-fire</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Hyperspectral Remote Sensing</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-divider></v-divider>
      </v-list-group>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-account-supervisor</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Personal Safety Beacon</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-tooltip-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Guardian - Tactical Situational Awareness Application</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon>mdi-map-marker-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Hyper Location App</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<style scoped>
</style>